import { Button } from 'amphitheatre';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { IoLogoFacebook, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io';
import SEO from 'src/Components/SEO';
import Layout from 'src/Layout';
import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';
import styled from 'styled-components';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
  }

  a button {
    padding: 1rem 4rem;
    display: inline-flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

function Links(): JSX.Element {
  return (
    <>
      <SEO
        title="Links | Saber Astronautics"
        description="Here are the links to all of Saber Astronautic's social media accounts"
        url="https://www.saberastro.com/links"
      />
      <Layout>
        <Hero.Wrapper style={{ minHeight: '30vh' }}>
          <Hero.Cover />
          <Hero.Text>
            <Hero.MajorHeader>Social Media Links</Hero.MajorHeader>
          </Hero.Text>
          <StaticImage
            className="hero-image"
            src="../images/clean-sun.png"
            alt="hero"
            objectPosition="75% top"
          />
        </Hero.Wrapper>
        <Hero.Header>
          <Base.Gap />
          <Base.Yellow>
            {' '}
            Links to Saber Astronautics' social media accounts.
          </Base.Yellow>
        </Hero.Header>
        <LinkContainer>
          <Base.Gap />
          <a
            href="https://www.facebook.com/SaberAstronautics/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button>
              <IoLogoFacebook />
              Facebook
            </Button>
          </a>
          <Base.Gap />

          <a
            href="https://twitter.com/SaberAstro"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button>
              <IoLogoTwitter />
              Twitter
            </Button>
          </a>
          <Base.Gap />
          <a
            href="https://www.linkedin.com/company/saber-astronautics"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button>
              <IoLogoLinkedin />
              LinkedIn
            </Button>
          </a>
          <Base.Gap />
        </LinkContainer>
      </Layout>
    </>
  );
}

export default Links;
